/** DEBUT HEADER **/
body{
  &.bg-color{
    background-color: #cccccc;
  }

  &.bg-pattern{
    background: url("../img/pattern.png") repeat;
  }

  &.record-podologie{
    .site-logo{
      background-image: url('../img/site-logo-podologie.jpg') !important;
    }
  }

}

.site-container{
  background-color: white;
  overflow: hidden;
}

.site-header{
  margin-bottom: $global-margin;
  position: relative;

  @media print {
    display: none;
  }

  /*#sticky_header{
    @include single-transition(all 300ms $default-transition-easing-function);
    !*position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;*!

    &.is-sticky{
      background-color: rgba(0,0,0,0.3);
    }
  }*/

  .site-logo-payoff-wrapper{

    display: flex;
    align-items: center;

    @media #{$small-only}{
      flex-direction: column;
    }

    .site-logo-wrapper{

      @media #{$small-only}{
        width: 100%;
      }

      .site-logo{
        width: 200px;
        height: 180px;
        /*background: url('../img/site-logo.png') no-repeat center;*/
        background: url('../img/site-logo-2.jpg') no-repeat center;
        background-size: 170px;
        /*background-size: contain;*/
        display: block;
        margin: auto;

        @media #{$small-only}{
          background-size: 130px;
          height: 130px;
        }
      }
    }

    .site-payoff-wrapper{
      font-family: $secondary-font-family;
      font-size: rem-calc(40);
      font-weight: normal;
      color: $gray;
      margin-right: $global-margin*2;
      line-height: 1.4;
      width: 100%;
      height: 180px;
      background-image: url('../img/banner.png');
      background-repeat: no-repeat;
      background-position: center;

      .payoff{
        text-align: center;
        div{
          display: inline-block;
        }
        div:first-child{
          border-top: 1px solid $gray;
          border-bottom: 1px solid $gray;
          @media #{$small-only}{
            border: none;
          }
        }
        div:last-child{
          border-top: 1px solid $gray;
          border-bottom: 1px solid $gray;
          @media #{$small-only}{
            border-bottom: none;
          }
        }
      }

      @media #{$small-only}{
        width: 100%;
        height: 60px;
        background-size: 100%;
        margin: $global-margin;
        text-align: center;
        font-size: rem-calc(27);
      }
    }
  }


  .menu-header-wrapper{
    @include single-transition(all 300ms $default-transition-easing-function);
    border: 0;
    color: $white;
    font-size: rem-calc(20);
    //margin-right: $global-margin;
    text-align: right;
    background-color: rgba(51,51,51,0.2);

    .record-accueil &{
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(51,51,51,0.2) 0%, rgba(51,51,51,0.2) 40%, rgba(51,51,51,0) 100%);
    }

    &.flat{
      //margin-bottom: $global-margin;
      text-align: center;
    }

    .bolt-menu{
      justify-content: center;
      align-items: center;

      .menu-item{
        line-height: 1.6;
        padding: rem-calc(10);
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
        text-shadow: 1px 1px 3px #383028;
        a{
          color: inherit;
          &.home-menu-item{
            //font-size: rem-calc(22);
            background-image: url("../img/favicon.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 30px;
            height: 30px;
            filter: invert(100%);
          }
        }

        &.current-parent, &.current, &:hover{
          //background-color: rgba($black,0.85);
          //border-bottom: 1px solid rgba($black,0.85);
          a{
            color: $primary-color;
          }
        }
      }

      .bolt-sub-menu {
        white-space: nowrap;
        z-index: 10;

        .menu-item{
          @include single-transition(all 0.5s $default-transition-easing-function);
          display: block;
          margin: 0;
          padding: 0;
          text-transform: capitalize;

          &:hover{
            background-color: rgba(200,200,200,0.2)
          }

          a{
            display: block;
            padding: rem-calc(7) rem-calc(10);
            color: $white;
          }
        }

      }
    }



    .nav_toggle-wrapper{
      display: none;
    }

    @media #{$small-only}{
      display: block;
      height: auto;
      border-bottom: 0;
      margin: 0;

      .nav_toggle-wrapper{
        display: block;
        position: absolute;
        top: 0;
        right: 5px;
        height: 52px;
        z-index: 10;
      }

      #nav_toggle{

        position: relative;
        display: inline-block;
        width: 35px;
        height: 35px;

        span,span:before,span:after{
          cursor: pointer;
          border-radius: 2px;
          height: 4px;
          width: 35px;
          background-color: $black;
          position: absolute;
          display: block;
          content: '';
          top:17px;
        }
        span:before{
          top: -8px
        }
        span:after{
          top: 8px
        }

        &.active{
          span{
            background-color: transparent;

            &:before{
              top: 0;
              transform: rotate(45deg);
              background-color: $white;
            }
            &:after{
              top: 0;
              transform: rotate(-45deg);
              background-color: $white;
            }
          }
        }

      }

      .menu-header-container{

        display: none;

        .bolt-menu{
          flex-flow: column nowrap;
          li{
            &.first{
              display: none;
            }
            margin: 0;
            line-height: rem-calc(22);
            font-size: rem-calc(20);
            text-align: center;
          }
        }
      }

    }
  }

  .slider-wrapper{
    width: 100%;
    position: relative;

    .slider-item{
      width: 100%;
      height: 100dvh;
      display: flex;
      /*justify-content: center;*/
      align-items: center;
      @media #{$small-only}{
        justify-content: center;
        text-align: center;
      }

      .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        filter: none;
        @include single-transition(filter 0.5s linear 1s);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
        background-color: rgba(33,33,33,0.2);
        //background: linear-gradient(to right,  rgba(33,33,33,0.2) 0%, rgba(33,33,33,0.2) 50%, rgba(33,33,33,0) 100%);
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#323232+0,323232+5,323232+95,323232+100&0+0,0.35+5,0.35+95,0+100 */
        //background: linear-gradient(to right,  rgba(50,50,50,0) 0%,rgba(50,50,50,0.2) 10%,rgba(50,50,50,0.2) 90%,rgba(50,50,50,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */


        //transform: translateX(-10%) translateZ(0) skewX(15deg);
      }

      .content{
        @include single-transition(all 0.5s $default-transition-easing-function 750ms);
        opacity: 0;
        padding: 30px;

        @media #{$medium-up}{
          &{
            text-align: center;
            margin-left: $global-margin*7;
          }
        }

        .title{
          transform: translateY(-350px);
          font-size: rem-calc(60);
          font-weight: bold;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.41);
          @media #{$small-only}{
            font-size: rem-calc(30);
          }
        }
        .caption{
          font-size: rem-calc(50);
          transform: translateY(350px);
          color: rgba(255, 255, 255, 0.9);
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.41);
          @media #{$small-only}{
            font-size: rem-calc(25);
          }
        }
      }

    }

    .active{
      .slider-item{

        &.slide-1{
          .bg{
            filter: none;
          }
        }

        .bg{
          filter: blur(3px);
        }

        .content{
          opacity: 0.9;

          .title{
            @include single-transition(all 750ms $default-transition-easing-function 500ms);
            transform: translateY(0);
          }
          .caption{
            @include single-transition(all 750ms $default-transition-easing-function 500ms);
            transform: translateY(0);
          }
        }
      }
    }


    nav{
      @include single-transition(all $default-transition-duration $default-transition-easing-function $default-transition-delay);
      position: absolute;
      top: 50%;
      z-index: 2;
      font-size: rem-calc(40);
      color: white;
      text-shadow: 0 0 1px #000000;
      opacity: 0.4;
      cursor: pointer;
    }
    nav:hover{
      opacity: 1;
    }
    nav.left{
      left: 10px;
    }
    nav.right{
      right: 10px;

    }


    @media #{$small-only}{
      display: block;
    }

  }

}

/** FIN HEADER **/



/** DEBUT SideBar **/

.sidebar{

  .title{
    font-family: $body-font-family;
    font-weight: bold;
    font-size: rem-calc(21);
    text-transform: uppercase;
  }

  /*
    .info-wrapper{
      margin-right: 1rem;
      &>div{
        margin: rem-calc(15) 0;
      }
    }

    .search-wrapper{

      .search-form-wrapper{
        position: relative;

        &:hover{
          .search-submit{
            color: $primary-color;
          }
        }

        .search-input{
          padding-right: rem-calc(40);
        }

        .search-submit{
          position: absolute;
          right: 0;
          top: 0;
          padding: rem-calc(3) rem-calc(8) 0;
          margin-bottom: 0;
          background: none;
          font-size: rem-calc(20);
          color: $black;
          border: 0;
        }
      }
    }

    .about-jl-wrapper{
      figure{

        text-align: center;
        margin-bottom: rem-calc(20);

        figcaption{
          width: 80%;
          margin: auto;
          background: black;
          color: white;
          text-align: center;
          font-family:
          $body-font-family;
          font-weight: bold;
          font-size: rem-calc(16);
        }

      }
      .teaser{
        font-size: rem-calc(14);
        text-align: justify;
      }
    }


    .favorites-wrapper{
      .menu-favorites-wrapper{
        li {
          list-style-type: none;
          text-transform: none;

          a{
            color: rgba($black,0.8);
            &:hover{
              color: $primary-color;
            }

            &.cat{
              margin-bottom: rem-calc(5);
              font-weight: bold;
              cursor: default;
            }
          }
        }
      }
    }
  */

}

/** FIN SideBar **/



/** DEBUT home **/

.home-wrapper{

    margin: $global-margin;

  .feature-items-wrapper{

    margin: $global-margin*3 0;

    .feature-item{

      visibility: hidden;
      margin: $global-margin 0;
      width: 100%;

      @media #{$small-only}{
        margin: 0 auto;
        visibility: visible;
        margin-bottom: $global-margin*2;
      }

      .picto{
        position: relative;
        aspect-ratio: 1;
        height: 70px;
        margin: $global-margin auto ;
        /*border-radius: 50%;
        border: 5px solid #333;*/
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;

        @media #{$small-only}{
          margin: 0 auto;
          width: 70px;
          height: 70px;
        }
      }

      .title{
        font-weight: bold;
        text-align: center;
        color: $body-font-color;
      }

      .txt{
        text-align: justify;
        color: $body-font-color;
      }

      &.item-1 .picto{
        background-image: url('../img/features/icon-facebook.png');
      }
      &.item-2 .picto{
        //background-image: url('../img/features/icon-forum.png');
        background-image: url('../img/features/icon-instagram.png');
      }
      &.item-3 .picto{
        background-image: url('../img/features/icon-road.png');
      }
      &.item-4 .picto{
        background-image: url('../img/features/icon-shop.png');
      }
      &.item-5 .picto{
        background-image: url('../img/features/icon-partners.png');
      }
      &.item-6 .picto{
        background-image: url('../img/features/icon-gallery.png');
      }
    }
  }

}

/** FIN home **/



/** DEBUT record **/

/* style generique appliques a tous les templates de record ( entry / page / etc ) */
.record-wrapper{

  a{
    text-decoration: underline;
    color: $primary-color;
  }

  table{
    border-width: 0;
  }

  h1,h2,h3{
    clear: both;
  }

  h1,h2{
    margin-bottom: $global-margin;
  }

  h1,.title{
    color: $primary-color;

    &:first-letter{
      text-transform: capitalize;
    }

    @media #{$small-only}{
      font-size: rem-calc(24);
    }
  }

  h2{
    @media #{$small-only}{
      font-size: rem-calc(22);
    }
  }
  h3{
    @media #{$small-only}{
      font-size: rem-calc(18);
    }
  }
  h4{
    @media #{$small-only}{
      font-size: rem-calc(16);
    }
  }
  .subtitle{

  }
  .img-wrapper{
    @extend %img-wrapper-inset-shadow;
    margin: 0 auto $global-margin;
  }
  .meta-wrapper{
    @extend %meta-wrapper;
  }

  .txt-404{
    text-align: center;
  }

  .body-wrapper{

    @media #{$small-only}{
      .embeddedContent{
        width: 100%;
        iframe{
          width: 100%;
          min-height: 250px;
        }
      }
    }

  }

  .record-nav-wrapper{
    margin: $global-margin 0;

    .nav-label{
      margin-bottom: $global-margin;
    }

    .title{
      font-size: rem-calc(14);
    }

    .previous-record-wrapper{
      .title{
        text-align: left;
      }
    }

    .next-record-wrapper{
      .title{
        text-align: right;
      }
    }
  }

  .addthis_toolbox{
    text-align: right;

    & > a{
      text-decoration: none;
      color: $black;
    }

    .at4-icon{
      display: none;
    }

  }

  .related-records-wrapper{
    margin: $global-margin 0;
  }
}

/** FIN record **/



/** DEBUT record-listing **/

.record-listing-wrapper{

  .title{
    font-size: rem-calc(26);
    margin-bottom: rem-calc(20);
  }

  article{

    margin-top: rem-calc(20);

    .title{
      font-size: rem-calc(20);
      margin-bottom: rem-calc(25);

      a{
        text-decoration: none;
      }

      @media #{$small-only}{
        font-size: rem-calc(16);
      }
    }

    .img-wrapper{
      margin: 0 rem-calc(20) rem-calc(10) 0;
      @extend %img-wrapper-inset-shadow;
    }
    .txt-wrapper{
    }
    .meta-wrapper{
      @extend %meta-wrapper;
    }

  }
}

/** FIN record-listing **/



/** DEBUT search-results **/

.search-results-wrapper{
  @extend .record-listing-wrapper;
}

/** FIN search-results **/



/** DEBUT photos **/

.photos-wrapper{

  .photo-wrapper{

    padding-bottom: $global-margin;
    margin-bottom: $global-margin;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 11px -5px rgba(36,36,36,0.3);

    .title{
      font-size: rem-calc(24);
      margin: 0 10px;
      a{
        text-decoration: none;
      }
    }

    .img-wrapper{
      width: 100%;
      img{
        width: 100%;
      }
    }

  }

}

/** FIN photos **/

@import "members/members";
@import "members/races";

/** DEBUT page **/

.page-wrapper{
  margin: 0 $global-margin*2;
}

/** FIN page **/


/** DEBUT simple Form **/

.page-with-form{

   .boltform-message, .boltform-error{
    font-size: rem-calc(16);
    font-weight: bold;
  }

}

/** FIN simple Form  **/

/** DEBUT receipt **/
.receipt{
  &-header{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  &-details{

    .button{
      @media print {
        display: none;
      }
    }
  }
}

/** FIN receipt  **/

/** DEBUT FOOTER **/

.site-footer{

  margin-top: rem-calc(20);

  @media print {
    display: none;
  }

  .footer-blocks-wrapper{

    position: relative;

    .map-wrapper{
      height: 300px;

      iframe{
        width: 100%;
        height: 100%;
      }
    }

    .info-wrapper{
      background-color: #3f3f3f;
      padding: 20px 35px 20px 20px;
      color: rgba(255, 255, 255, 0.7);
      height: 300px;

      @media #{$small-only}{
        height: auto;
      }

      &>div{
        margin: 15px 0;
      }

      .title{
        font-size: rem-calc(14);
        border-bottom: 5px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 10px;
        text-transform: uppercase;
      }

      .lbl{
        color: rgba(255, 255, 255, 0.4);
        .value{
          color: rgba(255, 255, 255, 0.7);
        }

      }

    }
  }

  .links-wrapper{
    padding: $paragraph-margin-bottom 0;
    background-color: #333333;
    color: rgba(255, 255, 255, 0.4);

    .links-container{
      text-align: center;


      &>div{
        display: inline-block;
        margin: 0 10px;
      }

      & a{
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
      }

      .footer-menu-wrapper{
        .menu-item{
          margin: 0 10px;

          &:first-child{
            margin-left: 0;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }

      .maker-info{

      }
    }
  }


}

/** FIN FOOTER **/


.sf-toolbar{
  @media print {
    display: none !important;
  }
}


.races-sections{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
