.members-list{
  td{
    text-align: center;
  }
}

.transaction-confirmation-table{
  th{
    text-align: right;
  }
  td{
    text-align: left;
  }
}
