/*permet d'afficher les list inline*/
ul.d-ib{
   display: flex;

  li{
    list-style: none;
    vertical-align: middle;
    margin: 0 rem-calc(13);

    &:first-child{
      margin-left: 0;
    }

    &:last-child{
      margin-right: 0;
    }
  }
}

.d-t{
  display: table;
  width: 100%;

  .d-tr{
    display: table-row;
  }
  .d-tc{
    display: table-cell;
    vertical-align: top;
  }

  @media #{$small-only} {
  &{
      display: block;
      width: 100%;

      .d-tr{
        display: block;
      }
      .d-tc{
        display: block;
      }
    }
  }
}



.primary-color{
  color: $primary-color;
}

.font-thirdary{
  font-family: $thirdary-font-family;
  font-weight: normal;
}

%img-wrapper-inset-shadow{
  /*@include box-shadow("inset 0 0 15px 0 rgba(0,0,0,0.3)");*/
  display: inline-block;
  position: relative;
  img{
    position: relative;
    /*z-index: -1;*/
  }
}

%meta-wrapper{
  h6 {
    color: $secondary-color;
    font-weight: 100;
    a {
      color: $dark-gray;
    }
  }
}

.color-white{
  color: $white;
}

.submit-button {
  padding: rem-calc(10) rem-calc(20);
  border: 1px solid #{$primary-color};
  color: $primary-color;
  @include single-transition(all $default-transition-duration $default-transition-easing-function);

  &:hover{
    color: white;
    background-color: $primary-color;
  }

  &.invert {
    background-color: $primary-color;
    color:white;
    border-color: $primary-color;

    &:hover{
      color: $primary-color;
      background-color:white;
      border-color: $primary-color;
    }
  }
}


.bolt-menu {

  margin: 0;
  font-family: $secondary-font-family;

  &.d-ib{
    .menu-item {

      .fa-caret-down{
        display: inline-block;
      }

      .bolt-sub-menu{
        @include single-transition(
                visibility 0s linear $default-transition-duration/2,
                opacity $default-transition-duration/2 $default-transition-easing-function $default-transition-delay
        );
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 100%;
        min-width: 100%;
        background-color: rgba(51,51,51,0.2);
        padding: 0;

        .menu-item{
          font-size: 0.9em;
        }

        @media #{$small-only} {
          position: relative;
          display: none;
          background-color: transparent;
        }
      }

      &:hover .bolt-sub-menu{
        @include single-transition(
                visibility 0s linear 0s,
                opacity $default-transition-duration/2 $default-transition-easing-function $default-transition-delay
        );
        visibility: visible;
        opacity: 1;
        @media #{$small-only} {
          display: block;
        }
      }
    }
  }

  .menu-item{
    position: relative;
    margin: 0;
    font-size: inherit;
    text-transform: capitalize;

    .fa-caret-down{
      display: none;
    }

    .current{
      color: $primary-color;
    }

    .bolt-sub-menu{
      padding-left: 20px;
    }
  }



}

.not-member {
  .show-for-member {
    display: none !important;
  }
}

.show-for-full-member {
  display: none !important;
}

.is-member {
  .hide-for-member{
    display: none !important;
  }
}
.is-full-member {
  .show-for-full-member{
    display: inherit !important;
  }
}
