
.race-registration-container{
  .fieldset{
    &[disabled]{
      position: relative;
      pointer-events: none;
      opacity: 0.6;
      &:after{
        content:'';
        position: absolute;
        inset: 0 0 0 0;
        background-color: rgba(33,33,33,0.2);
      }
    }
  }
  .input-group-label{
    width: 25%;
    min-width: 160px;
    padding: 0 10px;
  }

  .input-group-field{
    vertical-align: top;
    min-height: 1rem;
    input, select{
      box-sizing: border-box;
      padding: 0 $global-padding;
      height: 100%;
      width: calc(100% - #{$global-padding*2});
    }
  }

  #member_registration_drivers{
    width: 100%;
    input[type="checkbox"]{
      width: rem-calc(20);
      height: rem-calc(20);
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  #member_registration_carTrailer{
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #cacaca;
    padding: 0 10px;
    > * {
      width: auto;
      height: auto;
      margin-bottom: 0;
    }
  }
}
