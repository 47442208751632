
$default-transition-duration: 0.4s;
$default-transition-easing-function: cubic-bezier(0, 0, 0.2, 1);
$default-transition-delay: 0s;

@mixin box-shadow($params) {
  -webkit-box-shadow : #{$params};
  -moz-box-shadow : #{$params};
  box-shadow : #{$params};
}

@mixin single-transition($args...) {
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin keyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

/*
    Example usage:
    @include animation(10s, 5s, changecolour)
 */
@mixin animation ($delay, $duration, $animation, $timing-function: $default-transition-easing-function) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-timing-function: $timing-function;
  -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $timing-function;
  -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function: $timing-function;
  -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $timing-function;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}