figure{
  margin: 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea{
  /*margin: 0;*/
}

select{
 /* margin: 0;*/
}

textarea{
  margin: 0;
  border-width: 1px;
  min-height: 100px;
}

label{
  font-size: 1rem;
  &.required{
    &::after{
      content:'*'
    }
  }
}

h1{
  margin-bottom: $global-margin;
}

h1,h2,h3,h4,h5,h6{
  font-weight:bold;
}

[data-sr] {
  @media #{medium-up}{
    visibility: hidden;
  }
}



a{
  @include single-transition();

  /*&:hover, &:focus {
    color: $primary-color !important;
  }*/
}

ul{
  font-size: inherit;
  li{
    list-style-type: circle;
  }
}

table{
  width: 100%;

  input[type='text'], .button{
    margin-bottom: 0;
  }

  i.fa{
    font-size: 1.3rem;
    padding: 0 1rem;
    &.fa-info-circle{
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
}

